<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col>
                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id=12>
                    {{ $t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details') }}
                  </list-report-head>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                    <tbody>
                      <tr>
                        <th style="width: 15%" class="text-right" >{{ $t('elearning_tim.exam_date') }}</th>
                        <th class="text-center" style="width: 5%">:</th>
                        <td style="width: 30%" class="text-left">{{ formData.exam_date | dateFormat }}</td>
                        <th style="width: 15%"  class="text-right" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th class="text-center" style="width: 5%">:</th>
                        <td style="width: 30%" class="text-left">{{ formData.circular_memo_no }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_type') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_category') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_title') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('globalTrans.course') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.course_name_bn : formData.course_name }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_tim.exam_time_start') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ formData.exam_time_start }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_tim.exam_time_end') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ formData.exam_time_end }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>

                  <div class="question-area">
                    <b-table-simple class="tg mt-3" hover small caption-top>
                      <thead>
                      <tr>
                        <th>{{ $t('globalTrans.sl_no') }}</th>
                        <th>{{ $t('elearning_tim.question') }}</th>
                        <th class="text-right">{{ $t('elearning_tim.marks') }}</th>
                        <th class="text-right">{{ $t('elearning_tim.obtained_marks') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(answer, index) in formData.course_evaluation_answers" :key="index">
                        <td>{{ $n(index + 1) }}</td>
                        <td>{{ currentLocale === 'en' ? answer.evaluation_question.question : answer.evaluation_question.question_bn }}</td>
                        <td class="text-right">{{ $n(answer.evaluation_question.marks) }}</td>
                        <td class="text-right">{{ $n(answer.marks) }}</td>
                      </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <th class="text-right" colspan="2">{{ $t('globalTrans.total') }}</th>
                        <th class="text-right">{{ $n(totalMarks) }}</th>
                        <th class="text-right">{{ $n(totalObtainedMarks) }}</th>
                      </tr>
                      </tfoot>
                    </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      <!-- <pre>{{formData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'

export default {
    components: {
      ListReportHead
    },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getTotalMarks()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      formData: {},
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      totalMarks: 0,
      totalObtainedMarks: 0
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getTotalMarks () {
      let totalMarks = 0
      let totalObtainedMarks = 0
      this.formData.course_evaluation_answers.filter(answer => {
        totalMarks += answer.evaluation_question.marks
        totalObtainedMarks += answer.marks
      })
      this.totalMarks = totalMarks
      this.totalObtainedMarks = totalObtainedMarks
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.course_evaluation')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
    }
  }
}
</script>
