<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tim.course_evaluation')}} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="exam_date"
                >
                  <template v-slot:label>
                    {{ $t('elearning_tim.exam_date') }}
                  </template>
                  <b-form-input
                      class="fromDate"
                      id="exam_date"
                      v-model="search.exam_date"
                      :placeholder="$t('globalTrans.select_date')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="circular_memo_no"
                >
                  <template v-slot:label>
                    {{ $t('elearning_iabm.circular_memo_no') }}
                  </template>
                  <b-form-input
                      id="circular_memo_no"
                      v-model="search.circular_memo_no"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="training_title_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.training_title')}}
                  </template>
                  <v-select name="training_title_id"
                    v-model="search.training_title_id"
                    label="text"
                    :reduce="item => item.value"
                    :options="trainingTitleList"
                    :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="course_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_tim.course_name')}}
                  </template>
                  <v-select name="course_id"
                    v-model="search.course_id"
                    label="text"
                    :reduce="item => item.value"
                    :options="courseList"
                    :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
                <b-col lg="12" sm="12" class="text-right">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.course_evaluation')}} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(training_title)="data">
                                        <span class="capitalize">{{ data.item.training_title }}</span>
                                      </template>
                                      <template v-slot:cell(exam_date)="data">
                                        <span class="capitalize">{{ data.item.exam_date | dateFormat }}</span>
                                      </template>
                                      <template v-slot:cell(status)="data">
                                        <span class="badge badge-warning" v-if="data.item.status == 1">{{$t('elearning_config.pending')}}</span>
                                        <span class="badge badge-success" v-else>{{$t('globalTrans.approved')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-4 @click="edit(data.item)">
                                          <i class="fas fa-eye"></i>
                                        </a>
                                        <a href="javascript:" class="btn_table_action table_action_edit" > <router-link :to="{ path: '/training-e-learning-service/tim/course-evaluation-form', query: { id: data.item.id }}" size="sm"><i class="ri-ball-pen-fill m-0"></i> </router-link></a>
                                        <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.status === 1" @click="approve(data.item)">
                                          <i class="fa fa-toggle-on"></i></a>
                                        <a href="javacript:" class="btn_table_action table_action_toggle" v-if="data.item.status === 2" disabled><i class="fas fa-toggle-off"></i></a>
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('elearning_tim.course_evaluation') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseEvaluationList, courseEvaluationApprove } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'
import Details from './Details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        search: {
          exam_date: '',
          circular_memo_no: '',
          training_title_id: 0,
          course_id: 0
        },
        courseList: [],
        personalInfoList: []
      }
    },
    computed: {
      trainingTitleList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('elearning_tim.trainee'), class: 'text-center' },
          { label: this.$t('elearning_tim.exam_date'), class: 'text-center' },
          { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
          { label: this.$t('elearning_config.training_title'), class: 'text-center' },
          { label: this.$t('elearning_tim.course_name'), class: 'text-center' },
          { label: this.$t('elearning_tim.exam_time_start'), class: 'text-center' },
          { label: this.$t('elearning_tim.exam_time_end'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
          { key: 'index' },
          { key: 'trainee_name_bn' },
          { key: 'exam_date' },
          { key: 'circular_memo_no' },
          { key: 'training_title_bn' },
          { key: 'course_name_bn' },
          { key: 'exam_time_start' },
          { key: 'exam_time_end' },
          { key: 'status' },
          { key: 'action' }
          ]
        } else {
          keys = [
          { key: 'index' },
          { key: 'trainee_name' },
          { key: 'exam_date' },
          { key: 'circular_memo_no' },
          { key: 'training_title' },
          { key: 'course_name' },
          { key: 'exam_time_start' },
          { key: 'exam_time_end' },
          { key: 'status' },
          { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    watch: {
      'search.training_title_id': function (newValue) {
        this.courseList = this.getCourseList(newValue)
      }
    },
    async created () {
      this.loadData()
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      getCourseList (trainingTitleId) {
        const courseList = this.$store.state.TrainingElearning.commonObj.courseList
        if (trainingTitleId) {
          return courseList.filter(item => item.training_title_id === trainingTitleId)
        }
        return courseList
      },
      async searchData () {
        this.loadData()
      },
      changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            /** The following line commented as list is reloaded */
            // Store.dispatch('toggleStatus', item)
            if (destination !== null && dropdownName !== null) {
              Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
            }
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      approve (item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.approveMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatus(trainingElearningServiceBaseUrl, courseEvaluationApprove, item, 'training_e_learning')
          }
        })
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, courseEvaluationList, params).then(response => {
            if (response.success) {
              this.personalInfoList = response.personalInfoList
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const customItem = {}
          const personalInfoObj = this.personalInfoList.find(doc => doc.value === parseInt(item.personal_info_id))
          if (typeof personalInfoObj !== 'undefined') {
            customItem.trainee_name = personalInfoObj.text_en
            customItem.trainee_name_bn = personalInfoObj.text_bn
          } else {
            customItem.trainee_name = ''
            customItem.trainee_name_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            customItem.training_type = trainingTypeObj.text_en
            customItem.training_type_bn = trainingTypeObj.text_bn
          } else {
            customItem.training_type = ''
            customItem.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            customItem.training_category = trainingCategoryObj.text_en
            customItem.training_category_bn = trainingCategoryObj.text_bn
          } else {
            customItem.training_category = ''
            customItem.training_category_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            customItem.training_title = trainingTitleObj.text_en
            customItem.training_title_bn = trainingTitleObj.text_bn
          } else {
            customItem.training_title = ''
            customItem.training_title_bn = ''
          }
          const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(item.course_id))
          if (typeof courseObj !== 'undefined') {
            customItem.course_name = courseObj.text_en
            customItem.course_name_bn = courseObj.text_bn
          } else {
            customItem.course_name = ''
            customItem.course_name_bn = ''
          }

          return Object.assign({}, item, customItem)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>

<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
